import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../components/layout'

const Okompanii = () => {
  return (
    <Layout title="О компании" description="О компании" brcr={['О компании']}>
      <section className="catalog_page">
        <Container style={{ position: 'relative' }}>
          <h1>О КОМПАНИИ</h1>
          <div>
            <p style={{ fontSize: '20px' }}>
              Компания «SRUBOFF» - это крупнейший производитель бань-бочек, каркасных бань, гриль -
              домиков, беседок, садовых и дачных домиков, купелей и современных банных комплексов.
            </p>
            <p style={{ fontSize: '20px' }}>
              «SRUBOFF» - это компания, которая уже более 5 лет занимается производством бань-бочек,
              каркасных бань и других видов продукции. За это время объём производства успел вырасти
              с 80 до 400 изделий в год. Благодаря профессиональной работе нашей компании покупатель
              получает качественное, уютное и красивое изделие, которое прослужит не один десяток
              лет.{' '}
            </p>
            <p style={{ fontSize: '20px' }}>
              Бани, производимые нами, не требуют сложного обслуживания, просты в эксплуатации,
              каждый наш проект уникален и соответствует всем вашим запросам.
            </p>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Okompanii
